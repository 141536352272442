import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchExternalPersonalDetails,
	saveExternalPersonalDetails
} from 'redux/ContactInfo/request';
import { updateContactInfoRequestInput } from 'redux/ContactInfo/slice';
import { validateCityStateZipCombination } from 'redux/ValidateAddress/request';
import { updateValidatingAddressFlag } from 'redux/ValidateAddress/slice';

/**
 * Custom hook to consume the logics for VJO step indicators
 * @returns fetchContactInfo - callback to fetch the applicant info
 */
export const useContactInfo = () => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.account);
	const contactInfo = useSelector((state) => state?.ContactInfo?.data?.response);
	const contactInfoRequestInput = useSelector((state) => state?.ContactInfo?.data?.requestInput);
	const addressValidation = useSelector(
		(state) => state?.ValidateAddress?.data?.validateCombination
	);
	const saveContactInfoResponse = useSelector((state) => state?.ContactInfo?.data?.saveResponse);
	const isValidatingAddress = useSelector((state) => state?.ValidateAddress?.isValidatingAddress);
	const global = useSelector((state) => state.global);

	const fetchContactInfo = useCallback(() => {
		const applicantId = account?.data?.auth?.vjo?.applicantId;
		if (applicantId) {
			dispatch(
				fetchExternalPersonalDetails({
					applicantId: applicantId
				})
			);
		}
	}, [account, dispatch]);

	const updateContactInfo = useCallback(
		(requestInput) => {
			dispatch(updateContactInfoRequestInput(requestInput));
		},
		[dispatch]
	);

	const validateAddress = useCallback(
		(requestInput) => {
			dispatch(validateCityStateZipCombination(requestInput));
		},
		[dispatch]
	);

	const addressValidationStatus = useMemo(() => {
		if (addressValidation === undefined) {
			return addressValidation;
		}
		return !_.isEmpty(addressValidation)
			? addressValidation?.isValidCityStateCombination
				? false
				: addressValidation?.isValidCombination || addressValidation?.isAddressCheck
			: false;
	}, [addressValidation]);

	const updateIsValidatingAddress = useCallback(
		(value) => {
			dispatch(updateValidatingAddressFlag(value));
		},
		[dispatch]
	);

	const saveContactInfo = useCallback(() => {
		dispatch(saveExternalPersonalDetails(contactInfoRequestInput));
	}, [contactInfoRequestInput, dispatch]);

	return {
		fetchContactInfo,
		updateContactInfo,
		validateAddress,
		saveContactInfo,
		updateIsValidatingAddress,
		isValidatingAddress,
		contactInfo,
		addressValidationStatus,
		addressValidation,
		saveContactInfoResponse,
		global,
		contactInfoRequestInput
	};
};
