import {
	Card,
	Heading,
	Body,
	TextField,
	Select,
	Divider,
	Checkbox,
	Button,
	StyledText
} from '@walmart-web/livingdesign-components';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFill, Eye, EyeSlash } from '@livingdesign/icons';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import { useContactInfo } from 'pages/VirtualJobOffer/hooks/useContactInfo';
import moment from 'moment';
import { FIELDS } from './util';
import { STEPS } from 'pages/VirtualJobOffer/util';
import AlertDialog from 'components/AlertDialog';
import { useNavigate } from 'react-router';
import { onLogOut } from 'redux/slices/globalSlice';
import { useDispatch } from 'react-redux';
import { validateMobile } from 'utils/validation';
import { parsePhoneNumber } from 'libphonenumber-js';
import { SSN_LENGTH_TO_VALIDATE } from '../../../../../config/const';
import cookie from 'react-cookies';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';
import { saveExternalPersonalDetails } from 'redux/ContactInfo/request';

/**
 * Renders the Step1: Contact Info for the Portrait and Desktop views.
 */

export const stateCodeDrop = [
	'',
	'AL',
	'AK',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'GA',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'OH',
	'OK',
	'OR',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

const ContactInfo = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { callGetStepInd, account, jobOffer, isSupplyChain, nextStepInd } = useVirtualJobOffer();
	const {
		saveContactInfo,
		fetchContactInfo,
		updateContactInfo,
		validateAddress,
		updateIsValidatingAddress,
		isValidatingAddress,
		contactInfo,
		addressValidationStatus,
		addressValidation,
		saveContactInfoResponse,
		global,
		contactInfoRequestInput
	} = useContactInfo();
	const dispatch = useDispatch();
	const { getVJOisDrugScreenRequiredResponse } = useDrugScreenInfo();

	const [disableFields, setDisableFields] = useState(false);
	const monthRef = useRef();
	const dayRef = useRef();
	const yearRef = useRef();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	useEffect(() => {
		if (!contactInfo) {
			fetchContactInfo();
		}
	}, [contactInfo]);

	useEffect(() => {
		if (addressValidationStatus === undefined && (city === '' || zipCode === '')) {
			setStateCityZipCombinationError(false);
		} else if (addressValidationStatus) {
			setStateCityZipCombinationError(false);
			if(cookie.load('isCpPorterDsPilot') === 'true') {
				dispatch(saveExternalPersonalDetails(contactInfoRequestInput))
					.then((res) => {
						if(res.payload.responseCode == 200)
							getVJOisDrugScreenRequiredResponse();
					});
			} else {
				saveContactInfo();
			}
				
		} else {
			setStateCityZipCombinationError(true);
		}
	}, [addressValidationStatus, addressValidation]);

	useEffect(() => {
		if (nextStepInd === STEPS.CONTACT_INFO + 1) {
			if (saveContactInfoResponse?.responseCode === '200') {
				props.handleChangeCurrentStepStatus(true);
				props.handleChangeNextStep();
				callGetStepInd();
				fetchContactInfo();
			} else if (saveContactInfoResponse?.responseCode === '130') {
				setIsError(true);
				setErrorPopUpContent({
					title: t('virtualJobOffer.jobRequirement'),
					content: t('virtualJobOffer.ageErrorMsg'),
					button: t('vjoDSCheck.confirm'),
					confirmAction: handleDispositionAndShowDeclineMsg,
					closeAction: handleCloseErrorPopUp
				});
				props.handleChangeCurrentStepStatus(false);
			} else if (saveContactInfoResponse?.responseCode === '135') {
				setIsError(true);
				setErrorPopUpContent({
					title: t('general.oops'),
					content: saveContactInfoResponse?.response[global.language === 'ES' ? '102' : '101'],
					button: t('general.okay'),
					confirmAction: redirectToLogin,
					closeAction: redirectToLogin
				});
				props.handleChangeCurrentStepStatus(false);
			} else if (saveContactInfoResponse?.responseCode === '300') {
				setIsError(true);
				setErrorPopUpContent({
					title: t('selfSchedule.sorry'),
					content:
						saveContactInfoResponse?.responseMessage.split('#@#')[global.language === 'ES' ? 2 : 1],
					button: t('feedback.close'),
					confirmAction: handlePopupClose,
					closeAction: handlePopupClose
				});
				props.handleChangeCurrentStepStatus(false);
			} else if (saveContactInfoResponse?.responseCode === '500') {
				setIsError(true);
				setErrorPopUpContent({
					title: t('selfSchedule.sorry'),
					content: saveContactInfoResponse?.responseMessage,
					button: t('feedback.close'),
					confirmAction: handleLogout,
					closeAction: handleLogout
				});
				props.handleChangeCurrentStepStatus(false);
			} else {
				props.handleChangeCurrentStepStatus(false);
			}
		} else if (nextStepInd > STEPS.CONTACT_INFO + 1) {
			props.handleChangeCurrentStepStatus(true);
		}
	}, [saveContactInfoResponse, nextStepInd]);

	useEffect(() => {
		if (nextStepInd > STEPS.CONTACT_INFO + 1) {
			setDisableFields(true);
			setCheckConfirmBox(true);
		}
	}, [nextStepInd]);

	useEffect(() => {
		if (contactInfo) {
			setFirstName(contactInfo?.firstName || '');
			setLastName(contactInfo?.lastName || '');
			setPhoneNbrFormat(contactInfo?.phoneNbr || '');
			setEmail(contactInfo?.emailId || '');
			setStreetAddress(contactInfo?.addressLineOne || '');
			setStreetAddress2(contactInfo?.addressLineTwo || '');
			setCityName(contactInfo?.cityName || '');
			setStateCode(contactInfo?.stateProvCode || '');
			setZipCode(contactInfo?.postalCode || '');
			setSSN('');
			const savedSSN = contactInfo?.identificationNbr ? contactInfo?.identificationNbr.trim() : '';
			setSavedSSN(savedSSN);
			setSavedPartSSN(contactInfo?.partSsnNbr);
			setDateofBirth(contactInfo?.birthDate);

			contactInfo.firstName && setFirstNameError(false);
			contactInfo.lastName && setLastNameError(false);
			contactInfo.phoneNbr && setPhoneNumberError(false);
			contactInfo.addressLineOne && setStreetAddressError(false);
			contactInfo.cityName && setCityNameError(false);
			contactInfo.postalCode && setZipCodeError(false);
			if (contactInfo.partialDob) {
				const savedMonth = contactInfo.partialDob.substring(0, 2);
				const savedDate = contactInfo.partialDob.substring(2);
				setDate(savedDate);
				setMonth(savedMonth);
			}
			if (contactInfo.birthDate) {
				const [savedYear, savedMonth, savedDate] = contactInfo.birthDate.split('-');
				setDate(savedDate);
				setMonth(savedMonth);
				setYear(savedYear);
				const eligibleAge = isSupplyChain ? 18 : 16;
				const age = getAge(contactInfo.birthDate);
				if (age >= eligibleAge && age < 120) {
					setDOBError(false);
				} else {
					setDOBError(true);
				}
			}
		}
	}, [contactInfo, isSupplyChain]);

	const [isContactInfoSaved, setIsContactInfoSaved] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [error, setError] = React.useState({});
	const [phoneNumber, setPhoneNumber] = useState('');
	const [initPhoneNumber, setInitPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [streetAddress, setStreetAddress] = useState('');
	const [streetAddress2, setStreetAddress2] = useState('');
	const [city, setCityName] = useState('');
	const [stateCode, setStateCode] = useState(stateCodeDrop[0]);
	const [zipCode, setZipCode] = useState('');
	const [showSSN, setShowSSN] = useState(false);
	const [showSSNConfirm, setShowSSNConfirm] = useState(false);
	const [ssn, setSSN] = useState('');
	const [ssnConfirm, setSSNConfirm] = useState('');
	const [savedSSN, setSavedSSN] = useState('');
	const [savedPartSSN, setSavedPartSSN] = useState('');
	const [dob, setDateofBirth] = React.useState(null);
	const [date, setDate] = useState('');
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const [checkConfirmBox, setCheckConfirmBox] = useState(false);

	const [firstNameError, setFirstNameError] = useState(null);
	const [lastNameError, setLastNameError] = useState(null);
	const [phoneNumberError, setPhoneNumberError] = useState(null);
	const [streetAddressError, setStreetAddressError] = useState(null);
	const [streetAddress2Error, setStreetAddress2Error] = useState(null);
	const [cityNameError, setCityNameError] = useState(null);
	const [zipCodeError, setZipCodeError] = useState(null);
	const [SSNError, setSSNError] = useState(null);
	const [SSNConfirmError, setSSNConfirmError] = useState(null);
	const [DOBError, setDOBError] = useState(null);
	const [invalidDOBError, setInvalidDOBError] = useState(null);
	const [stateCityZipCombinationError, setStateCityZipCombinationError] = useState(null);
	const [isError, setIsError] = useState(false);
	const [errorPopUpContent, setErrorPopUpContent] = useState(null);

	useEffect(() => {
		if (isContactInfoSaved && isValidatingAddress) {
			const requestInput = {
				requestInput: {
					city: city,
					stateProvCode: stateCode,
					postalCode: zipCode,
					countryCode: stateCode === 'PR' ? 'PR' : 'US'
				}
			};
			validateAddress(requestInput);
			updateIsValidatingAddress(false);
		}
	}, [
		city,
		contactInfo?.countryCod,
		isContactInfoSaved,
		isValidatingAddress,
		stateCode,
		updateIsValidatingAddress,
		validateAddress,
		zipCode
	]);

	const handleOnChange = (e, name) => {
		let value = e?.target?.value;
		let regex = /^[a-z A-Z ,.'-]+$/;
		switch (name) {
			case FIELDS.firstName:
				setFirstName(removeInvalidChars(value));
				if (!regex.test(value)) {
					setFirstNameError(true);
				} else {
					setFirstNameError(false);
				}
				break;
			case FIELDS.lastName:
				setLastName(removeInvalidChars(value));
				if (!regex.test(value)) {
					setLastNameError(true);
				} else {
					setLastNameError(false);
				}
				break;
			case FIELDS.phoneNumber:
				dispatch(validateMobile(value, error, setError, setPhoneNumber));
				break;
			case FIELDS.email:
				setEmail(value);
				break;
			case FIELDS.streetAddress:
				regex = /^(?=.*[0-9])(?=.*[A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F])/;
				setStreetAddress(
					value
						.replace(/[^0-9A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'\/']/gi, '')
						.replace(/^\s+/g, '')
						.slice(0, 30)
				);
				if (!regex.test(value)) {
					setStreetAddressError(true);
				} else {
					setStreetAddressError(false);
				}
				break;
			case FIELDS.streetAddress2:
				let newValue= value.replace(/[^0-9A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'\/']/gi, '')
				.replace(/^\s+/g, '')
				.slice(0, 30);
				setStreetAddress2(newValue);
				validateSSNinStreetAddress2(newValue, ssn);
				break;
			case FIELDS.city:
				regex = /^(?=.*[A-Za-zÀ-ÖØ-öø-ÿ])/;
				setCityName(removeInvalidChars(value.slice(0, 30)));
				if (stateCityZipCombinationError) {
					setStateCityZipCombinationError(false);
				}
				if (!regex.test(value)) {
					setCityNameError(true);
				} else {
					setCityNameError(false);
				}
				break;
			case FIELDS.stateCode:
				setStateCode(value);
				if (stateCityZipCombinationError) {
					setStateCityZipCombinationError(false);
				}
				break;
			case FIELDS.zipcode:
				regex = /^[0-9]*$/;
				if (!regex.test(value)) {
					setZipCodeError(true);
				} else {
					setZipCode(value);
					if (stateCityZipCombinationError) {
						setStateCityZipCombinationError(false);
					}
					if (value !== '' && value.length === 5) {
						setZipCodeError(false);
					} else {
						setZipCodeError(true);
					}
				}
				break;
			case FIELDS.ssn:
				regex = /^[0-9]*$/;
				value = value.replace(/-/g, '');
				if (!regex.test(value)) {
					setSSNError(true);
				} else {
					const formattedSSN = getSSNFormat(value);
					setSSN(formattedSSN);
					if (value !== '') {
						if (savedSSN.length === 9 && savedSSN !== value) {
							setSSNError(true);
							setSSNConfirmError(true);
						} else {
							const regex = /^(?!666|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
							if (!regex.test(value) || value === '123456789' || value === '987654321') {
								setSSNError(true);
								setSSNConfirmError(true);
							} else {
								setSSNError(false);
								setSSNConfirmError(false);
							}
						}
						if (savedSSN.length !== 9 && value !== ssnConfirm.replace(/-/g, '')) {
							setSSNConfirmError(true);
						} else {
							setSSNConfirmError(false);
						}
					} else {
						setSSNError(true);
					}
					validateSSNinStreetAddress2(streetAddress2, formattedSSN);
				}
				break;
			case FIELDS.confirmSSN:
				regex = /^[0-9]*$/;
				value = value.replace(/-/g, '');
				if (regex.test(value)) {
					const formattedSSN = getSSNFormat(value);
					setSSNConfirm(formattedSSN);
					if (value === ssn.replace(/-/g, '')) {
						setSSNConfirmError(false);
					} else {
						setSSNConfirmError(true);
					}
				}
				break;
			case FIELDS.date:
				regex = /^[0-9]*$/;
				if (regex.test(value)) {
					setDate(value);

					if (year && month && value) {
						if (month.length === 1) {
							setMonth(`0${month}`);
						}
						let strDate = `${year}-${month}-${value}`;
						if (moment(strDate, 'YYYY-MM-DD', true).isValid()) {
							setDateofBirth(strDate);
							const eligibleAge = isSupplyChain ? 18 : 16;
							const age = getAge(strDate);
							if (age >= eligibleAge && age < 120) {
								setDOBError(false);
							} else {
								setDOBError(true);
							}
							setInvalidDOBError(false);
						} else {
							setInvalidDOBError(true);
						}
					} else {
						setDateofBirth(null);
						setDOBError(false);
						setInvalidDOBError(false);
					}
					if (value.length >= 2) {
						yearRef.current.focus();
					} else if (value.length === 0) {
						monthRef.current.focus();
					}
				}
				break;

			case FIELDS.month:
				regex = /^[0-9]*$/;
				if (regex.test(value)) {
					setMonth(value);

					if (year && date && value) {
						if (date.length === 1) {
							setDate(`0${date}`);
						}
						let strMonth = `${year}-${value}-${date}`;
						if (moment(strMonth, 'YYYY-MM-DD', true).isValid()) {
							setDateofBirth(strMonth);
							const eligibleAge = isSupplyChain ? 18 : 16;
							const age = getAge(strMonth);
							if (age >= eligibleAge && age < 120) {
								setDOBError(false);
							} else {
								setDOBError(true);
							}
							setInvalidDOBError(false);
						} else {
							setInvalidDOBError(true);
						}
					} else {
						setDateofBirth(null);
						setDOBError(false);
						setInvalidDOBError(false);
					}

					if (value.length >= 2) {
						dayRef.current.focus();
					}
				}
				break;

			case FIELDS.year:
				regex = /^[0-9]*$/;
				if (regex.test(value)) {
					setYear(value);

					if (date && month && value) {
						if (date.length === 1) {
							setDate(`0${date}`);
						}
						if (month.length === 1) {
							setMonth(`0${month}`);
						}
						let strYear = `${value}-${month}-${date}`;
						if (moment(strYear, 'YYYY-MM-DD', true).isValid()) {
							setDateofBirth(strYear);
							const eligibleAge = isSupplyChain ? 18 : 16;
							const age = getAge(strYear);
							if (age >= eligibleAge && age < 120) {
								setDOBError(false);
							} else {
								setDOBError(true);
							}
							setInvalidDOBError(false);
						} else {
							setInvalidDOBError(true);
						}
					} else {
						setDateofBirth(null);
						setDOBError(false);
						setInvalidDOBError(false);
					}
					if (value.length === 0) {
						dayRef.current.focus();
					}
				}
				break;

			default:
				break;
		}
	};

	const handlePopupClose = () => {
		setIsError(false);
	}

	const getSSNFormat = (value) => {
		if (value.length > 3 && value.length <= 5) {
			return value.slice(0, 3) + '-' + value.slice(3, 5);
		} else if (value.length > 5) {
			return value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5);
		}
		return value;
	};

	const setPhoneNbrFormat = (phoneNbr) => {
		if (phoneNbr !== '' && phoneNbr.length >= 10) {
			setInitPhoneNumber(
				'(' + phoneNbr.slice(0, 3) + ') ' + phoneNbr.slice(3, 6) + '-' + phoneNbr.slice(6)
			);
			setPhoneNumber(
				'(' + phoneNbr.slice(0, 3) + ') ' + phoneNbr.slice(3, 6) + '-' + phoneNbr.slice(6)
			);
		}
	};

	const getAge = (dob) => {
		const today = moment();
		const birthDate = moment(dob);
		let age = today.format('YYYY') - birthDate.format('YYYY');
		const month = today.format('M') - birthDate.format('M');
		if (month < 0 || (month === 0 && Number(today.format('D')) < Number(birthDate.format('D')))) {
			age--;
		}
		return age;
	};

	const getSaveContactInfoRequest = useCallback(() => {
		const storeNbr = Number(jobOffer?.storeNbr);

		return {
			applicantId: account?.data?.auth?.vjo?.applicantId,
			requisitionId: account?.data?.auth?.vjo ? account.data.auth.vjo.reqsnId : '',
			isSupplyChain: account?.data?.auth?.supplyChain,
			storeNbr: storeNbr,
			requestInput: {
				applicantId: account?.data?.auth?.vjo?.applicantId,
				rehireCheck: true,
				requisitionId: account?.data?.auth?.vjo ? account?.data?.auth?.vjo.reqsnId : '',
				addressLineOne: streetAddress,
				addressLineTwo: streetAddress2,
				city: city,
				cityName: city,
				state: stateCode,
				stateProvCode: stateCode,
				countryCode: contactInfo?.countryCode,
				postalCode: zipCode,
				ssnNbr: ssn.replace(/-/g, ''),
				userSsn: ssn.replace(/-/g, ''),
				birthDate: dob,
				jobOfferNbr: jobOffer ? jobOffer.jobOfferNbr : '',
				storeNbr: storeNbr,
				phoneNbr: parsePhoneNumber(phoneNumber, global.countryCode).nationalNumber
			}
		};
	}, [
		account,
		city,
		contactInfo,
		dob,
		jobOffer,
		ssn,
		stateCode,
		streetAddress,
		streetAddress2,
		zipCode,
		phoneNumber
	]);

	const handleClickShowSSN = () => {
		setShowSSN(!showSSN);
	};

	const handleClickShowSSNConfirm = () => {
		setShowSSNConfirm(!showSSNConfirm);
	};

	const countryCode = global.countryCode === 'PR' ? 'PR' : 'US';

	const handleCheckConfirmBox = (e) => {
		setCheckConfirmBox(e.target.checked);
	};

	const handleCloseErrorPopUp = () => {
		setIsError(false);
	};

	const handleLogout = () => {
		dispatch(onLogOut());
	};

	const handleDispositionAndShowDeclineMsg = () => {
		props.declineOffer(1037);
	};

	const removeInvalidChars = (value) => {
		let check = value;
		if (countryCode === 'PR') {
			check = check.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'']/gi, '');
		} else {
			check = check.replace(/[^\w\s\.\-\'']/gi, '');
		}
		check = check.replace(/^\s+/g, '');
		check = check.replace(/\d+/g, '');
		check = check.replace(/_/g, '');
		return check.toUpperCase();
	};

	const validateContactInfo = useCallback(() => {
		if (nextStepInd === STEPS.CONTACT_INFO + 1) {
			if (
				checkConfirmBox &&
				!firstNameError &&
				!lastNameError &&
				!phoneNumberError &&
				phoneNumber &&
				!error.mobile &&
				!streetAddressError &&
				!cityNameError &&
				city &&
				!zipCodeError &&
				zipCode &&
				!SSNError &&
				ssn &&
				!SSNConfirmError &&
				!invalidDOBError &&
				!DOBError &&
				dob &&
				!streetAddress2Error
			) {
				const requestInput = getSaveContactInfoRequest();
				updateContactInfo(requestInput);
				setIsContactInfoSaved(true);
				props.handleChangeCurrentStepStatus(true);
			} else {
				setIsContactInfoSaved(false);
				props.handleChangeCurrentStepStatus(false);
			}
		}
	}, [
		checkConfirmBox,
		firstNameError,
		lastNameError,
		phoneNumberError,
		cityNameError,
		streetAddressError,
		zipCodeError,
		SSNError,
		SSNConfirmError,
		DOBError,
		dob,
		error,
		phoneNumber,
		city,
		ssn,
		stateCode,
		streetAddress,
		streetAddress2,
		zipCode,
		streetAddress2Error
	]);

	useEffect(() => {
		validateContactInfo();
	}, [
		checkConfirmBox,
		firstNameError,
		lastNameError,
		phoneNumberError,
		cityNameError,
		streetAddressError,
		streetAddress2,
		stateCode,
		zipCodeError,
		SSNError,
		SSNConfirmError,
		DOBError,
		dob,
		error,
		phoneNumber,
		city,
		ssn,
		streetAddress,
		zipCode,
		streetAddress2Error
	]);

	const redirectToLogin = () => {
		setIsError(false);
		navigate('../login');
	};

	const validateSSNinStreetAddress2 = (streetAddress2Val, ssnVal)=>  {
		let tempStreetAddress2 = streetAddress2Val;
		tempStreetAddress2 = tempStreetAddress2.replace(/[^0-9]/g,'')
		let tempSSN = ssnVal;
		tempSSN  = tempSSN.replace(/-/g, '');
		if( tempStreetAddress2?.length > SSN_LENGTH_TO_VALIDATE ) 
		{
			if ((tempStreetAddress2!=='' &&  savedSSN !=='' && tempStreetAddress2.includes(savedSSN))
				|| (tempStreetAddress2 !== '' && tempSSN !== '' && tempStreetAddress2.includes(tempSSN))) {
				setStreetAddress2Error(true);
			}
			else {
				setStreetAddress2Error(false);
			}
		}
		else {
			setStreetAddress2Error(false);
		}
	}

	const errorPopUp = useMemo(() => {
		if (errorPopUpContent) {
			const { title, content, button, confirmAction, closeAction } = errorPopUpContent;
			return (
				<AlertDialog isOpen={isError} title={title} onClose={closeAction}>
					<div className="pop_up_container">
						<Body children={content} size="small" UNSAFE_className="pop_up_body" />
						<Button variant="primary" size="small" children={button} onClick={confirmAction} />
					</div>
				</AlertDialog>
			);
		}
	}, [isError, saveContactInfoResponse, t, errorPopUpContent]);

	return (
		<React.Fragment>
			{isError && errorPopUp}
			<Card UNSAFE_className="contact_info_card_container">
				<Heading children={t('virtualJobOffer.verifyInfo')} size="small" />
				<Body
					as="p"
					children={t('virtualJobOffer.infoAccurate')}
					size="medium"
					UNSAFE_className="verify_contact_info_description"
				/>
				<div className="verify_details_container">
					<div className="verify_details_field">
						<TextField
							textFieldProps={{
								className: 'verify_tf_rd_size'
							}}
							label={t('virtualJobOffer.firstName')}
							readOnly
							UNSAFE_className="verify_tf_rd_size"
							type="text"
							name={FIELDS.firstName}
							error={firstNameError && t('virtualJobOffer.invalidFirstName')}
							onChange={(e) => handleOnChange(e, FIELDS.firstName)}
							value={firstName}
						/>
					</div>
					<div className="verify_details_field">
						<TextField
							textFieldProps={{
								className: 'verify_tf_rd_size'
							}}
							label={t('virtualJobOffer.lastName')}
							readOnly
							UNSAFE_className="verify_tf_rd_size"
							type="text"
							name={FIELDS.lastName}
							error={lastNameError && t('virtualJobOffer.invalidLastName')}
							onChange={(e) => handleOnChange(e, FIELDS.lastName)}
							value={lastName}
						/>
					</div>
				</div>
				<div className="verify_details_container">
					<div className="verify_details_field">
						<TextField
							textFieldProps={{
								inputMode: 'numeric',
								className: initPhoneNumber || disableFields ? 'verify_tf_rd_size' : 'verify_tf_size'
							}}
							label={t('virtualJobOffer.phoneNumber')}
							readOnly={initPhoneNumber || disableFields}
							UNSAFE_className={initPhoneNumber || disableFields ? 'verify_tf_rd_size' : 'verify_tf_size'}
							type="text"
							name={FIELDS.phoneNumber}
							onChange={(e) => handleOnChange(e, FIELDS.phoneNumber)}
							value={phoneNumber}
							error={
								phoneNumber && error && error.mobile ? t('virtualJobOffer.invalidPhoneNum') : ''
							}
						/>
					</div>
					<div className="verify_details_field">
						<TextField
							textFieldProps={{
								className: 'verify_tf_rd_size'
							}}
							label={t('virtualJobOffer.email')}
							readOnly
							UNSAFE_className="verify_tf_rd_size"
							type="email"
							name={FIELDS.email}
							onChange={(e) => handleOnChange(e, FIELDS.email)}
							value={email}
						/>
					</div>
				</div>
				<div className="verify_details_container">
					<div className="verify_details_field">
						<TextField
							textFieldProps={{
								className: 'verify_tf_size'
							}}
							label={t('virtualJobOffer.streetAddress')}
							disabled={disableFields}
							UNSAFE_className="verify_tf"
							type="text"
							name={FIELDS.streetAddress}
							error={streetAddressError && t('virtualJobOffer.invalidAddr')}
							onChange={(e) => handleOnChange(e, FIELDS.streetAddress)}
							value={streetAddress}
						/>
					</div>
					<div className="verify_details_field">
						<TextField
							textFieldProps={{
								className: 'verify_tf_size'
							}}
							label={t('virtualJobOffer.streetAddress2')}
							disabled={disableFields}
							UNSAFE_className="verify_tf"
							type="text"
							name={FIELDS.streetAddress2}
							onChange={(e) => handleOnChange(e, FIELDS.streetAddress2)}
							value={streetAddress2}
							error={streetAddress2Error && t('virtualJobOffer.confirmStreetAddress2')}
						/>
					</div>
				</div>
				<div className="verify_details_container">
					<div className="verify_details_field">
						<TextField
							textFieldProps={{
								maxLength: 30,
								className: 'verify_tf_size'
							}}
							label={t('virtualJobOffer.city')}
							disabled={disableFields}
							error={
								stateCityZipCombinationError
									? t('virtualJobOffer.enterValidCombination')
									: cityNameError && t('virtualJobOffer.invalidCity')
							}
							UNSAFE_className="verify_tf"
							type="text"
							name={FIELDS.city}
							onChange={(e) => handleOnChange(e, FIELDS.city)}
							value={city}
						/>
					</div>
					<div className="verify_details_field">
						<Select
							selectProps={{
								className: 'verify_tf_size'
							}}
							label={t('virtualJobOffer.state')}
							disabled={disableFields}
							UNSAFE_className="verify_tf"
							name={FIELDS.stateCode}
							value={stateCode}
							onChange={(e) => handleOnChange(e, FIELDS.stateCode)}>
							{stateCodeDrop.map((obj, index) => {
								return (
									<option key={index} value={obj}>
										{obj}
									</option>
								);
							})}
						</Select>
					</div>
					<div className="verify_details_field">
						<TextField
							type="search"
							disabled={disableFields}
							label={t('virtualJobOffer.zipCode')}
							name={FIELDS.zipcode}
							error={zipCodeError && t('virtualJobOffer.invalidZipCode')}
							textFieldProps={{
								maxLength: 5,
								className: 'verify_tf_size'
							}}
							UNSAFE_className="verify_tf"
							onChange={(e) => handleOnChange(e, FIELDS.zipcode)}
							value={zipCode}
						/>
					</div>
				</div>
				<div className="verify_details_container">
					<div className="verify_details_field">
						<TextField
							type={showSSN ? 'text' : 'password'}
							onChange={(e) => handleOnChange(e, FIELDS.ssn)}
							size="small"
							name={FIELDS.ssn}
							label={t('virtualJobOffer.ssn')}
							value={disableFields ? '***' + savedPartSSN : ssn}
							disabled={disableFields}
							error={SSNError && t('virtualJobOffer.invalidSsn')}
							textFieldProps={{
								maxLength: 11,
								className: 'verify_tf_size'
							}}
							UNSAFE_className="verify_tf"
							trailing={
								showSSN ? (
									<EyeSlash
										style={{ padding: '16px' }}
										size="medium"
										onClick={handleClickShowSSN}
									/>
								) : (
									<Eye style={{ padding: '16px' }} size="medium" onClick={handleClickShowSSN} />
								)
							}
						/>
					</div>
					{savedSSN?.length !== 9 ? (
						<div className="verify_details_field">
							<TextField
								type={showSSNConfirm ? 'text' : 'password'}
								label={t('virtualJobOffer.confirmSSN')}
								onChange={(e) => handleOnChange(e, FIELDS.confirmSSN)}
								size="small"
								name={FIELDS.confirmSSN}
								value={ssnConfirm}
								disabled={disableFields}
								error={SSNConfirmError && t('virtualJobOffer.ssnNotMatch')}
								textFieldProps={{
									maxLength: 11,
									className: 'verify_tf_size'
								}}
								UNSAFE_className="verify_tf"
								trailing={
									showSSNConfirm ? (
										<EyeSlash
											style={{ padding: '16px' }}
											size="medium"
											onClick={handleClickShowSSNConfirm}
										/>
									) : (
										<Eye
											style={{ padding: '16px' }}
											size="medium"
											onClick={handleClickShowSSNConfirm}
										/>
									)
								}
							/>
						</div>
					) : (
						''
					)}
					<div className="verify_details_field verify_details_date_container">
						<Body
							children={t('virtualJobOffer.dateOfBirth')}
							weight={700}
							size="small"
							UNSAFE_className={disableFields ? 'verify_dt_heading_disabled' : 'verify_dt_heading'}
						/>
						<div className="verify_details_datefield">
							<TextField
								type="text"
								size="small"
								name={FIELDS.month}
								ref={monthRef}
								placeholder="MM"
								helperText={t('general.month')}
								disabled={disableFields}
								onChange={(newValue) => handleOnChange(newValue, FIELDS.month)}
								textFieldProps={{
									maxLength: 2,
									className: 'verify_tf_dt_size',
									inputMode: 'numeric'
								}}
								UNSAFE_className="verify_tf_dt_size verify_tf_dt_month"
								value={month}
							/>
							<TextField
								type="text"
								size="small"
								name={FIELDS.date}
								ref={dayRef}
								placeholder="DD"
								helperText={t('general.day')}
								disabled={disableFields}
								onChange={(newValue) => handleOnChange(newValue, FIELDS.date)}
								textFieldProps={{
									maxLength: 2,
									className: 'verify_tf_dt_size',
									inputMode: 'numeric'
								}}
								UNSAFE_className="verify_tf_dt_size verify_tf_dt_date"
								value={date}
							/>
							<TextField
								type="text"
								size="small"
								name={FIELDS.year}
								ref={yearRef}
								placeholder="YYYY"
								helperText={t('general.year')}
								disabled={disableFields}
								onChange={(newValue) => handleOnChange(newValue, FIELDS.year)}
								textFieldProps={{
									maxLength: 4,
									className: 'verify_tf_dt_size',
									inputMode: 'numeric'
								}}
								UNSAFE_className="verify_tf_dt_size"
								value={year}
							/>
						</div>
						{(DOBError || invalidDOBError) && (
							<div style={{ display: 'flex', color: '#de1c24', margin: '0.25rem 0.25rem 0rem' }}>
								<div style={{ height: '14px', width: '14px' }}>
									<ExclamationCircleFill />
								</div>

								<StyledText
									color="red"
									size="small"
									children={
										invalidDOBError
											? t('virtualJobOffer.invalidDate')
											: t('virtualJobOffer.invalidDob')
									}
									UNSAFE_style={{ lineHeight: '1rem', marginLeft: '0.3rem' }}
								/>
							</div>
						)}
					</div>
				</div>
				<Divider />
				<div className="info_correct">
					<Checkbox
						label={t('virtualJobOffer.confirmDetails')}
						onChange={(e) => handleCheckConfirmBox(e)}
						checked={checkConfirmBox}
						disabled={disableFields}
					/>
				</div>
			</Card>
		</React.Fragment>
	);
};

export default ContactInfo;
