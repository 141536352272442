import {
	Card,
	Button,
	ProgressTracker,
	ProgressTrackerItem,
	WizardFooter,
	ProgressIndicator
} from '@walmart-web/livingdesign-components';
import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { PAGES, STEPS } from '../../../util';
import { useContactInfo } from 'pages/VirtualJobOffer/hooks/useContactInfo';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';
import { useVjoStepIndicator } from 'pages/VirtualJobOffer/hooks/useVjoStepIndicator';
import { useDisplay } from 'utils/useDisplay';
import cookie from 'react-cookies';

const ProgressTrackerComp = (props) => {
	const { isPortrait } = useDisplay();
	const { t } = useTranslation();
	const { isSupplyChain, nextStepInd, updateFinalizingJobOffer } = useVirtualJobOffer();
	const { updateIsValidatingAddress } = useContactInfo();
	const { callGetVjoDsEndpt, drugScreenRequiredResponse } = useDrugScreenInfo();
	const { updateStepIndicator } = useVjoStepIndicator();
	const [offerSteps, setOfferSteps] = useState(t('vjoDashboard.pagesWithoutDS', {returnObjects: true}));

	useEffect(() => {
		let tempSteps;
		tempSteps = drugScreenRequiredResponse?.drugScreenRequired ? t('vjoDashboard.pages', {returnObjects: true}) : t('vjoDashboard.pagesWithoutDS', {returnObjects: true});
		if(cookie.load('isCpPorterDsPilot') === 'true' &&
		  drugScreenRequiredResponse?.drugScreenRequired === null &&
		  !drugScreenRequiredResponse?.fullSsnAvailable
		){
		  tempSteps = t('vjoDashboard.pagesWithOptionalDS', {returnObjects: true});
		}
		setOfferSteps(tempSteps);
	},[drugScreenRequiredResponse, t]);

	
	const totalSteps = useMemo(() => {
			return offerSteps?.length
	}, [offerSteps]);
	const virtualJobOfferPrevious = 'virtualJobOffer.previous';

	const handleButtonClickPrevious = () => {
		props.handleChangePreviousStep();
	};

	const handleButtonClickContinue = () => {
		if (drugScreenRequiredResponse?.drugScreenRequired) {
			if (props.currentStep === PAGES.CONTACT_INFO) {
				if (nextStepInd === STEPS.CONTACT_INFO + 1) {
					updateIsValidatingAddress(true);
				} else {
					props.handleChangeNextStep();
				}
			} else if (props.currentStep === PAGES.AVAILABILITY) {
				if (nextStepInd === STEPS.AVAILABILITY + 1) {
					updateStepIndicator();
				}
				props.handleChangeNextStep();
			} else if (props.currentStep === PAGES.REVIEW) {
				props.handleChangeNextStep();
			} else if (props.currentStep === PAGES.ACKNOWLEDGE) {
				if (nextStepInd === STEPS.ACKNOWLEDGE + 1) {
					updateFinalizingJobOffer(true);
				} else if (nextStepInd === STEPS.ACKNOWLEDGE_DECLINED + 1) {
					props.showDeclineOffer();
				} else {
					props.handleChangeNextStep();
				}
			} else if (props.currentStep === PAGES.DRUG_SCREEN_CHECK) {
				if (nextStepInd === STEPS.DRUG_SCREEN_CHECK + 1) {
					callGetVjoDsEndpt(true);
				} else {
					props.handleChangeNextStep();
				}
			} else if (props.currentStep === PAGES.BACKGROUND_CHECK) {
				if (
					nextStepInd === STEPS.BACKGROUND_CHECK_WITHOUT_DS_REQUIRED + 1 ||
					nextStepInd === STEPS.BACKGROUND_CHECK_WITH_DS_REQUIRED + 1
				) {
					updateStepIndicator();
				}
				props.handleChangeNextStep();
			}
		} else {
			if (props.currentStep === PAGES.CONTACT_INFO) {
				if (nextStepInd === STEPS.CONTACT_INFO + 1) {
					updateIsValidatingAddress(true);
				} else {
					props.handleChangeNextStep();
				}
			} else if (props.currentStep === PAGES.AVAILABILITY) {
				if (nextStepInd === STEPS.AVAILABILITY + 1) {
					updateStepIndicator();
				}
				props.handleChangeNextStep();
			} else if (props.currentStep === PAGES.REVIEW) {
				props.handleChangeNextStep();
			} else if (props.currentStep === PAGES.ACKNOWLEDGE) {
				if (nextStepInd === STEPS.ACKNOWLEDGE + 1) {
					updateFinalizingJobOffer(true);
				} else if (nextStepInd === STEPS.ACKNOWLEDGE_DECLINED + 1) {
					props.showDeclineOffer();
				} else {
					props.handleChangeNextStep();
				}
			} else if (props.currentStep === PAGES.BACKGROUND_CHECK - 1) {
				if (
					nextStepInd === STEPS.BACKGROUND_CHECK_WITHOUT_DS_REQUIRED + 1 ||
					nextStepInd === STEPS.BACKGROUND_CHECK_WITH_DS_REQUIRED + 1
				) {
					updateStepIndicator();
				}
				props.handleChangeNextStep();
			}
		}
	};

	const handleContinueButtonVerbiage = useMemo(() => {
		if (props.currentStep === PAGES.ACKNOWLEDGE) {
			if (nextStepInd === STEPS.ACKNOWLEDGE + 1) {
				return t('virtualJobOffer.acceptOffer');
			} else if (nextStepInd === STEPS.ACKNOWLEDGE_DECLINED + 1) {
				return t('virtualJobOffer.declineOffer');
			}
		}
		return t('general.continue');
	}, [t, nextStepInd, props.currentStep]);

	const TaskBar = useMemo(() => {
		return (
			<ProgressTracker
				activeIndex={props.currentStep - 1}
				variant={props.currentStep > totalSteps ? 'success' : 'info'}
				className="progess-tracker-container">
				{Array.isArray(offerSteps) && offerSteps.map((offer) => (
					<ProgressTrackerItem key={offer.step}>
						{isSupplyChain ? offer.scvalue : offer.value}
					</ProgressTrackerItem>
				))}
			</ProgressTracker>
		);
	}, [props.currentStep, isSupplyChain, offerSteps, totalSteps]);

	const TaskBarMobile = useMemo(() => {
		if (props.currentStep > totalSteps) {
			return (
				<ProgressIndicator
					min={1}
					max={totalSteps}
					value={totalSteps}
					label={t('vjoCongratulations.congrats')}
					valueLabel={`100% ${t('virtualJobOffer.completed')}`}
					variant="success"
					UNSAFE_className="progress-indicator"
				/>
			);
		} else {
			return (
				<WizardFooter
					previousActionProps={{
						disabled: props.currentStep <= PAGES.CONTACT_INFO,
						size: 'small',
						variant: 'primary',
						children: t(virtualJobOfferPrevious),
						onClick: handleButtonClickPrevious,
						UNSAFE_className:
							props.currentStep === PAGES.CONTACT_INFO
								? 'wizard_footer_hide_previous'
								: 'steps-continue-btn'
					}}
					nextActionProps={{
						disabled: !props.stepStatus[props.currentStep],
						size: 'small',
						variant: 'primary',
						children: handleContinueButtonVerbiage,
						onClick: handleButtonClickContinue,
						UNSAFE_className: 'steps-continue-btn'
					}}>
					<ProgressIndicator
						min={1}
						max={totalSteps + 1}
						value={props.currentStep}
						label={Array.isArray(offerSteps) && offerSteps.map((offer) => {
							if (offer.step === props.currentStep) {
								return isSupplyChain ? offer.scvalue : offer.value;
							} else {
								return '';
							}
						})}
						valueLabel={`${Math.round(((props.currentStep - 1) / totalSteps) * 100)}% ${t(
							'virtualJobOffer.completed'
						)}`}
						variant="info"
					/>
				</WizardFooter>
			);
		}
	}, [props.currentStep, t, props.stepStatus, totalSteps]);

	return (
		<React.Fragment>
			<MediaQuery minWidth={850}>
				<Card className="steps-footer-container">
					{props.currentStep > PAGES.CONTACT_INFO && props.currentStep <= totalSteps ? (
						<Button
							size="medium"
							variant="secondary"
							onClick={handleButtonClickPrevious}
							className="steps-previous-btn">
							{t(virtualJobOfferPrevious)}
						</Button>
					) : (
						<div className="steps-fotter-left-margin"></div>
					)}
					{TaskBar}
					{props.currentStep <= totalSteps ? (
						<Button
							size="medium"
							variant="primary"
							disabled={!props.stepStatus[props.currentStep]}
							onClick={handleButtonClickContinue}
							className="steps-continue-btn">
							{handleContinueButtonVerbiage}
						</Button>
					) : (
						<div className="steps-fotter-left-margin" />
					)}
				</Card>
			</MediaQuery>
			<MediaQuery maxWidth={850}>
				<Card className="steps-footer-container">
					{isPortrait ? TaskBarMobile : TaskBar}
					{!isPortrait ? (
						<div className="steps-footer-mobile-btn">
							{props.currentStep > PAGES.CONTACT_INFO && props.currentStep < totalSteps && (
								<Button
									size="small"
									variant="secondary"
									onClick={handleButtonClickPrevious}
									className="steps-previous-btn">
									{t(virtualJobOfferPrevious)}
								</Button>
							)}
							{props.currentStep <= totalSteps && (
								<Button
									size="small"
									variant="primary"
									disabled={!props.stepStatus[props.currentStep]}
									onClick={handleButtonClickContinue}
									className="steps-continue-btn">
									{handleContinueButtonVerbiage}
								</Button>
							)}
						</div>
					) : null}
				</Card>
			</MediaQuery>
		</React.Fragment>
	);
};

export default ProgressTrackerComp;
