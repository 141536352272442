import { Body, Divider, Heading } from '@walmart-web/livingdesign-components';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import JobOfferDetails from './JobOfferDetails';
import JobOfferSteps from './JobOfferSteps';
import './styles.scss';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';

/**
 * VJO Dashboard screen. Visible when nextStepInd is > 0
 * @returns - memoized view
 */
const VJODashboard = () => {
	const { t } = useTranslation();
	const { account } = useVirtualJobOffer();
	const { drugScreenRequiredResponse, getVJOisDrugScreenRequiredResponse } = useDrugScreenInfo();

	useEffect(() => {
		if (typeof drugScreenRequiredResponse?.drugScreenRequired != 'boolean' && typeof drugScreenRequiredResponse?.fullSsnAvailable != 'boolean') {
			getVJOisDrugScreenRequiredResponse();
		}
	}, [drugScreenRequiredResponse?.drugScreenRequired]);

	return (
		<>
			<div className="dash_header">
				<div className="dash_header_container">
					<Heading
						size="small"
						weight={700}
						children={t('vjoDashboard.title', {
							applicantName: account?.data?.auth?.applicantName
						})}
						UNSAFE_className="dash_header_title"
					/>
					<Body
						size="medium"
						children={t('vjoDashboard.subTitle')}
						UNSAFE_className="dash_header_title"
					/>
					<Divider UNSAFE_className="dash_header_divider" />
				</div>
			</div>
			<div className="dash_body">
				<div className="dash_frame_container">
					<JobOfferSteps />
					<JobOfferDetails />
				</div>
			</div>
		</>
	);
};

export default memo(VJODashboard);
