import React, { useEffect } from 'react';
import styles from './JobDetails.module.css';
import {
	Alert,
	Tag,
	BottomSheetCloseButton,
	Button,
	BottomSheet,
	Modal,
	Divider
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { subHeader } from '../../../redux/slices/globalSlice';
import InfoItem from './InfoItem/InfoItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { updateCurrentSelectedJobs } from '../../../redux/slices/jobSearchSlice';
import { useDisplay } from 'utils/useDisplay';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ServicesInterface } from '@livingdesign/pictograms/dist/svg/App_&_Web_Interface/ServicesInterface.svg';
import { ReactComponent as Thermometer } from '../../../assets/svg/thermometer.svg';
import { ReactComponent as Vest } from '../../../assets/svg/vest.svg';
import { MoneyCircle } from "@livingdesign/icons";


const Icon = require('@livingdesign/icons');
const JobDetails = (props) => {
	const { isPortrait } = useDisplay();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const selectedJobsStore = useSelector((state) => state.jobSearch);

	const { t } = useTranslation();
	const global = useSelector((state) => state.global);

	const item = selectedJobsStore.jobDetails;

	const shiftWdAfternoon = 'shift.wdAfternoon';
	const jobDetailsAvailableShifts = 'jobDetails.availableShifts';
	const availabilityShftTiming = 'availability.shftTiming';

	const [isOverrideJobOverviewSection, setOverrideJobOverviewSection] = React.useState(false);
	const [overriddenDesc, setOverriddenDesc] = React.useState({});

	useEffect(() => {
		shouldOverrideJobDescSection();
	},[item]);

	const [expanded, setExpanded] = React.useState(true);
	const handleChange = () => {
		setExpanded(!expanded);
	};

	const shouldOverrideJobDescSection = () => {
		const selectedLocation = Object.keys(item.openPositionsStoreWise ? item.openPositionsStoreWise : item.payRangesByFacility);
		if(selectedLocation.length === 1 && item.jobOverviewOverrideDesc[selectedLocation[0]]) {
			setOverrideJobOverviewSection(true);
			setOverriddenDesc(item.jobOverviewOverrideDesc[selectedLocation[0]]);
		}
		else { 
			setOverrideJobOverviewSection(false);
			setOverriddenDesc({});
		}

	}

	const selectCurrent = () => {
		if (props.select && props.changeClosed) {
			props.select(item);
			props.changeClosed(false);
		} else {
			const tempArray = [...selectedJobsStore.currentSelectedJobs];
			!tempArray.some((i) => i.jobSelectionId === item.jobSelectionId)
				? tempArray.push(item)
				: tempArray.splice(
					tempArray.findIndex((i) => i.jobSelectionId === item.jobSelectionId),
					1
				);
			dispatch(updateCurrentSelectedJobs(tempArray));
			navigate('../job-search');
		}
	};

	const checkIfPreferred = (item) => {
		return selectedJobsStore.currentSelectedJobs.some(
			(i) => i.jobSelectionId === item.jobSelectionId && i.jobPreferred === 'Y'
		);
	};
	const getEmploymentTypeText = (i, empType) => {
		switch (empType) {
			case 'F':
				return t('availability.fullTime');
			case 'P':
				return t('availability.partTime');
			case 'T':
				return t('availability.justTemporary');
			default:
				return 'All';
		}
	};
	const mapShift = (job, shift) => {
		const fType = getFacilityType(job);
		switch (shift) {
			case 1:
				return fType === 'store' ? t('shift.wdMorning') : t('shift.wd1');
			case 2:
				return fType === 'store' ? t(shiftWdAfternoon) : t('shift.wd2');
			case 3:
				return fType === 'store' ? t('shift.wdOvernight') : t('shift.wd3');
			case 4:
				return fType === 'store' ? t(shiftWdAfternoon) : t('shift.we1');
			case 5:
				return fType === 'store' ? t(shiftWdAfternoon) : t('shift.we2');
			case 6:
				return fType === 'store' ? t('shift.wdOvernight') : t('shift.we3');
			case 7:
				return t('shift.shift1');
			case 8:
				return t('shift.shift2');
			case 9:
				return t('shift.shift3');
			case 10:
				return t('shift.opening');
			case 11:
				return t('shift.morning');
			case 12:
				return t('shift.midShift');
			case 13:
				return t('shift.closing');
			case 14:
				return t('shift.overnight');
			case 17:
				return t('shift.flex');
			case 18:
				return t('shift.flex');
			default:
				return 'All';
		}
	};

	const getFacilityType = (i) => {
		return i.workgroupId < 5000 ? 'store' : 'warehouse';
	};
	const shouldBe18 = () => {
		return getFacilityType(item) === 'warehouse' || item.countryCode === 'PR';
	};
	const alertMsg = () => {
		return item.countryCode === 'PR'
			? t('jobSelection.mustBe18AlertPR')
			: t('jobSelection.mustBe18AlertSC');
	};
	const getTagColor = (i) => {
		const fType = getFacilityType(i);
		switch (fType) {
			case 'store':
				return 'blue';
			case 'warehouse':
				return 'purple';
			default:
				return 'blue';
		}
	};
	const getIcon = (i) => {
		const fType = getFacilityType(i);
		switch (fType) {
			case 'store':
				return <Icon.Store />;
			case 'warehouse':
				return <Icon.Truck />;
			default:
				return <Icon.Store />;
		}
	};

	const handleJobOverviewDescription = (str) => {
		if(str.indexOf('*') > -1) {
		const strings = str.split('*');
		return <>
					<div>
						{strings[0]}
					</div>
					<br/>
					<div>
						{'*' + strings[1]}
					</div>
				</>
		} else {
			return <div>{str}</div>
		}
	}

	const detailsComp = () => {
		return (
			<div className={styles.jobDetailsButtonPadding}>
				{shouldBe18() ? (
					<div className={styles.footerWarningContainer}>
						<Alert variant="warning" style={{ marginBottom: '10px' }}>
							{alertMsg()}
						</Alert>{' '}
					</div>
				) : (
					''
				)}
				<div className={styles.jobDetailsHeading}>
					<div style={{ width: '85px' }} />
					<img
						className={styles.jobDetailsHeadImage}
						src={require('../../../assets/images/workgroups/' + item.workgroupId + '.svg')}
						alt="Job icon"
						aria-hidden = {true}
					/>
					<div>
						<Tag color={getTagColor(item)} variant="secondary" leading={getIcon(item)}>
							{t('general.' + getFacilityType(item))}
						</Tag>
					</div>
				</div>
				<div className={styles.jobDetailsTitleText} style={{ textAlign: 'center' }}>
					{item.jobSelectionDesc[global.language === 'ES' ? 102 : 101]}
				</div>
				{item.payRange ? (
					<InfoItem icon={<Icon.MoneyCircle size="small" />}>
						<div>
							{item.payRange && (
								<div className={styles.jobDetailsText}>
									{t('jobDetails.startingPay')}:{' '}
									<b>
										{`$${Number(item.payRange.min).toFixed(2)} - $${Number(item.payRange.max).toFixed(2)}`}
                                        {t('general./hr')}
									</b>
								</div>
							)}
							<div className={styles.jobDetailsTinyText}>
								{t('jobDetails.compensation')}
								<br />
								<br />
								{t('jobDetails.compensation1')}
								<br />
								{t('jobDetails.compensation2')}
							</div>
						</div>
					</InfoItem>
				) : null}
				{item.employmentTypes && item.employmentTypes.length > 0 && (
					<InfoItem icon={<ServicesInterface className={styles.svgIcon} fill="currentColor" />}>
						<div>
							<div className={styles.jobDetailsText}>
							{t('jobSelection.employmentType')}:{' '}{item.employmentTypes.map((i, index) => (
									<b key={index}>
										{getEmploymentTypeText(item, i)}
										{index < item.employmentTypes.length - 1 ? ', ' : ''}
									</b>
								))}
								<b>{' *'}</b>
							</div>
						</div>
					</InfoItem>
				)}
				{item.shiftIds && item.shiftIds.length > 0 && (
					<InfoItem icon={<Icon.Calendar size="small" />}>
						<div>
							<div
								data-testid="jobDetailsInfoItem"
								className={styles.jobDetailsText}
								onClick={() => {
									setDrawerOpen(true);
									if (props.updateForce) {
										props.updateForce(true);
									}
								}}>
								<u>{t(jobDetailsAvailableShifts)}:</u>
							</div>
							<div className={styles.jobDetailsText}>
								{item.shiftIds.map((i, index) => (
									<b key={index}>
										{mapShift(item, i)}
										{index < item.shiftIds.length - 1 ? ', ' : ''}
									</b>
								))}
								<b>{' *'}</b>
							</div>
						</div>
					</InfoItem>
				)}
				{item.workingCondId ? (
					<InfoItem icon={<Thermometer className={styles.svgIcon} fill="currentColor" />}>
						<div className={styles.jobDetailsText}>
							{t('jobFilter.workingConditions')}:{' '}
							<b>{item.workingCondDesc[global.language === 'ES' ? 102 : 101]}</b>
						</div>
					</InfoItem>
				) : (
					''
				)}
				{item.customerIntrId ? (
					<InfoItem icon={<Icon.User size="small" />}>
						<div className={styles.jobDetailsText}>
							{t('jobFilter.customerInteraction')}:{' '}
							<b>{item.customerIntrDesc[global.language === 'ES' ? 102 : 101]}</b>
						</div>
					</InfoItem>
				) : (
					''
				)}
					<div className={styles.jobDetailsTinyText} style={{ marginBottom: '12px' }}>
						*{t('jobDetails.jobLocation')}
					</div>

				<Divider />
				{item.openPositionsStoreWise ? (
					<Accordion
						expanded={expanded}
						onChange={handleChange}
						elevation={0}
						sx={{
							'&:before': {
								display: 'none'
							}
						}}
						disableGutters
						className={styles.jobDetailsExpansionPanelContainer}>
						<AccordionSummary
							classes={{ content: styles.accordianSummaryContent }}
							className={styles.removePadding}
							expandIcon={<Icon.ChevronDown size="medium" />}>
							<div className={styles.jobDetailsSubTitle}>
								<div>{t('welcomePage.location')} </div>
							</div>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '16px 8px' }}>
							{Object.keys(item.openPositionsStoreWise).map((i, index) => {
								return (
									<>
										<div key={index}>
											<div className={styles.jobDetailsSubTitle}>
												{item.openPositionsStoreWise[i].storeDescription}
											</div>

											<InfoItem icon={<Icon.Location size="small" />}>
												<div>
													<div className={styles.jobDetailsText}>
														{item.openPositionsStoreWise[i].streetAddr.replace(
															/(^\w|\s\w)(\S*)/g,
															(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
														)}
													</div>
													<div className={styles.jobDetailsText}>
														{item.openPositionsStoreWise[i].cityAddr.replace(
															/(^\w|\s\w)(\S*)/g,
															(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
														)}
														, {item.openPositionsStoreWise[i].stateAddr}{' '}
														{item.openPositionsStoreWise[i].zipCode}
													</div>
												</div>
											</InfoItem>
											<InfoItem icon={<Vest className={styles.svgIcon} fill="currentColor" />}>
												<div>
													<div className={styles.jobDetailsText}>
														{item.openPositionsStoreWise[i].noOfPositions}{' '}
														{t('jobSelection.positionsOpen')}
													</div>
												</div>
											</InfoItem>
											<InfoItem icon={<MoneyCircle className={styles.svgIcon} fill="currentColor" />}>
												<div className={styles.jobDetailsText}>
													{item.payRangesByFacility[i] &&
														(`$${Number(item.payRangesByFacility[i].payRange.min).toFixed(2)} - $${Number(item.payRangesByFacility[i].payRange.max).toFixed(2)}`)
													}
													{t('general./hr')}
												</div>
											</InfoItem>

											{item.openPositionsStoreWise[i].employmentTypes &&
												item.openPositionsStoreWise[i].employmentTypes.length > 0 && (
													<InfoItem
														icon={
															<ServicesInterface className={styles.svgIcon} fill="currentColor" />
														}>
														<div>
															<div className={styles.jobDetailsText}>
																{item.openPositionsStoreWise[i].employmentTypes.map((x, index) => {
																	return (
																		<span key={index}>
																			{getEmploymentTypeText(item, x)}
																			{index <
																				item.openPositionsStoreWise[i].employmentTypes.length - 1
																				? ','
																				: ''}{' '}
																		</span>
																	);
																})}
															</div>
														</div>
													</InfoItem>
												)}
											{item.openPositionsStoreWise[i].shiftIds &&
												item.openPositionsStoreWise[i].shiftIds.length > 0 && (
													<InfoItem icon={<Icon.Calendar size="small" />}>
														<div>
															<div className={styles.jobDetailsText}>
																{item.openPositionsStoreWise[i].shiftIds.map((x, index) => {
																	return (
																		<span key={index}>
																			{mapShift(item, x)}
																			{index < item.openPositionsStoreWise[i].shiftIds.length - 1
																				? ','
																				: ''}{' '}
																		</span>
																	);
																})}
																{t('shift.shift')}
																{item.openPositionsStoreWise[i].shiftIds.length > 1 ? 's' : ''}
															</div>
														</div>
													</InfoItem>
												)}

											{!isOverrideJobOverviewSection && item.jobOverviewOverrideDesc[i] &&
											<>
												<div className={styles.jobDetailsText}>{t('jobDetails.jobOverview')}</div>	
												<div>
													<div className={styles.jobDetailsTinyText}>
														{handleJobOverviewDescription(item.jobOverviewOverrideDesc[i][global.language === 'ES' ? 102 : 101])}
													</div>
												</div>
											</>}
										</div>

										{index === Object.keys(item.openPositionsStoreWise).length - 1 ? (
											''
										) : (
											<div style={{ padding: '16px 0' }}>
												<Divider />
											</div>
										)}
									</>
								);
							})}
						</AccordionDetails>
					</Accordion>
				) : (
					<Accordion
						expanded={expanded}
						onChange={handleChange}
						elevation={0}
						sx={{
							'&:before': {
								display: 'none'
							}
						}}
						disableGutters
						className={styles.jobDetailsExpansionPanelContainer}>
						<AccordionSummary
							classes={{ content: styles.accordianSummaryContent }}
							className={styles.removePadding}
							expandIcon={<Icon.ChevronDown size="medium" />}>
							<div className={styles.jobDetailsSubTitle}>
								<div>{t('welcomePage.location')} </div>
							</div>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '16px 8px' }}>
							{Object.keys(item.payRangesByFacility).map((i, index) => {
								return (
									<>
										<div key={index}>
											<div className={styles.jobDetailsSubTitle}>
												{item.payRangesByFacility[i].storeDescription}
											</div>

											<InfoItem icon={<Icon.Location size="small" />}>
												<div>
													<div className={styles.jobDetailsText}>
														{item.payRangesByFacility[i].streetAddr.replace(
															/(^\w|\s\w)(\S*)/g,
															(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
														)}
													</div>
													<div className={styles.jobDetailsText}>
														{item.payRangesByFacility[i].cityAddr.replace(
															/(^\w|\s\w)(\S*)/g,
															(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
														)}
														, {item.payRangesByFacility[i].stateAddr}{' '}
														{item.payRangesByFacility[i].zipCode}
													</div>
												</div>
											</InfoItem>
											<InfoItem
												icon={<MoneyCircle className={styles.svgIcon} fill="currentColor" />}>
												{item.payRangesByFacility[i] && (
													<div className={styles.jobDetailsText}>
														{item.payRangesByFacility[i] &&
															(`$${Number(item.payRangesByFacility[i].payRange.min).toFixed(2)} - $${Number(item.payRangesByFacility[i].payRange.max).toFixed(2)}`)
														}
														{t('general./hr')}
													</div>
												)}
											</InfoItem>

											{!isOverrideJobOverviewSection && item.jobOverviewOverrideDesc[i] && 
											<div>
												<div className={styles.jobDetailsText}>{t('jobDetails.jobOverview')}</div>	
												<div className={styles.jobDetailsTinyText}>
													{handleJobOverviewDescription(item.jobOverviewOverrideDesc[i][global.language === 'ES' ? 102 : 101])}
												</div>
											</div>}
										</div>

										{index === Object.keys(item.payRangesByFacility).length - 1 ? (
											''
										) : (
											<div style={{ padding: '16px 0' }}>
												<Divider />
											</div>
										)}
									</>
								);
							})}
						</AccordionDetails>
					</Accordion>
				)}
				<Accordion
					elevation={0}
					sx={{
						'&:before': {
							display: 'none'
						}
					}}
					disableGutters
					className={styles.jobDetailsExpansionPanelContainer}>
					<AccordionSummary
						classes={{ content: styles.accordianSummaryContent }}
						className={styles.removePadding}
						expandIcon={<Icon.ChevronDown size="medium" />}>
						<div className={styles.jobDetailsSubTitle}>
							<div>{t('jobDetails.jobOverview')}</div>
						</div>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '16px 8px' }}>
						<div className={styles.jobDetailsText}>
							{isOverrideJobOverviewSection && overriddenDesc
								? handleJobOverviewDescription(overriddenDesc[global.language === 'ES' ? 102 : 101])
								: item.jobOverviewDesc[global.language === 'ES' ? 102 : 101]}
						</div>
					</AccordionDetails>
				</Accordion>
				<Accordion
					elevation={0}
					sx={{
						'&:before': {
							display: 'none'
						}
					}}
					disableGutters
					className={styles.jobDetailsExpansionPanelContainer}>
					<AccordionSummary
						classes={{ content: styles.accordianSummaryContent }}
						className={styles.removePadding}
						expandIcon={<Icon.ChevronDown size="medium" />}>
						<div className={styles.jobDetailsSubTitle}>
							<div>{t('jobDetails.benefits')}</div>
						</div>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '16px 8px' }}>
						<div className={styles.jobDetailsText}>
							{t('jobDetails.competitivePay')}
							<br />
							<br />
							{t('jobDetails.competitivePay1')}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://one.walmart.com/content/usone/en_us/me/health/smartguide.html">
								{t('jobDetails.competitivePay2')}
							</a>
							<br />
							<br />
							{t('jobDetails.competitivePay3')}
							<br />
							<br />
							{t('jobDetails.competitivePay4')}
							<a target="_blank" rel="noreferrer" href="http://One.Walmart.com">
								{t('jobDetails.competitivePay5')}
							</a>
						</div>
					</AccordionDetails>
				</Accordion>

				{bottomDrawer()}
			</div>
		);
	};
	const shiftDetails = () => {
		return getFacilityType(item) === 'warehouse' ? (
			item.countryCode === 'US' ? (
				<div>
					<Alert variant="info" style={{ marginBottom: '10px' }}>
						<div>
							<b>{t('jobDetails.wdshifts')}</b>
						</div>
						<div>{t('jobDetails.wdshift1')}</div>
						<br></br>
						<div>
							<b>{t('jobDetails.weshifts')}</b>
						</div>
						<div>{t('jobDetails.weshift1')}</div>
						<br></br>
						<div>
							<b>{t('jobDetails.flex')}</b>
						</div>
						<div>{t('availability.flexShiftDesc')}</div>
						<br></br>
						<div>*{t('jobDetails.weshift2')}</div>
					</Alert>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.wd1')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '3:00am', end: '10:30am' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.wd2')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '11:00am', end: '5:30pm' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.wd3')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '6:00pm', end: '2:30am' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.we1')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '3:00am', end: '10:30am' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.we2')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '11:00am', end: '5:30pm' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.we3')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '6:00pm', end: '2:30am' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.flex')}</div>
						<div className={styles.jobDetailsText}>
							{t('availability.shiftsMayVary')}
						</div>
					</div>
				</div>
			) : (
				<div>
					<Alert variant="info" style={{ marginBottom: '10px' }}>
						<div>{t('availability.shftAlert') + ' ' + t('availability.prWarehouseShift')}</div>
					</Alert>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.shift1')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '3:00am', end: '10:00am' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.shift2')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '10:30am', end: '6:00pm' })}
						</div>
					</div>
					<div className={styles.detailsDrawerPadding}>
						<div className={styles.jobDetailsTinyText}>{t('shift.shift3')}</div>
						<div className={styles.jobDetailsText}>
							{t(availabilityShftTiming, { start: '6:30pm', end: '2:30am' })}
						</div>
					</div>
				</div>
			)
		) : (
			<div>
				<Alert variant="info" style={{ marginBottom: '10px' }}>
					<div>{t('jobDetails.shiftApplicable')}</div>
				</Alert>
				<div className={styles.detailsDrawerPadding}>
					<div className={styles.jobDetailsTinyText}>{t('shift.opening')}</div>
					<div className={styles.jobDetailsText}>
						{t(availabilityShftTiming, { start: '4:00am', end: '7:00am' })}
					</div>
				</div>
				<div className={styles.detailsDrawerPadding}>
					<div className={styles.jobDetailsTinyText}>{t('shift.morning')}</div>
					<div className={styles.jobDetailsText}>
						{t(availabilityShftTiming, { start: '7:00am', end: '9:00am' })}
					</div>
				</div>
				<div className={styles.detailsDrawerPadding}>
					<div className={styles.jobDetailsTinyText}>{t('shift.midShift')}</div>
					<div className={styles.jobDetailsText}>
						{t(availabilityShftTiming, { start: '8:00am', end: '11:00am' })}
					</div>
				</div>
				<div className={styles.detailsDrawerPadding}>
					<div className={styles.jobDetailsTinyText}>{t('shift.closing')}</div>
					<div className={styles.jobDetailsText}>
						{t(availabilityShftTiming, { start: '1:00pm', end: '4:00pm' })}
					</div>
				</div>
				<div className={styles.detailsDrawerPadding}>
					<div className={styles.jobDetailsTinyText}>{t('shift.overnight')}</div>
					<div className={styles.jobDetailsText}>
						{t(availabilityShftTiming, { start: '8:00pm', end: '11:00pm' })}
					</div>
				</div>
			</div>
		);
	};
	const bottomDrawer = () => { 
		return props.select && props.changeClosed ? (
			<Modal
				isOpen={drawerOpen}
				onClose={(e) => {
					setDrawerOpen(false);
				}}
				size="small"
				title={<div className={styles.drawerTitle}>{t(jobDetailsAvailableShifts)}</div>}>
				{shiftDetails()}
			</Modal>
		) : (
			<BottomSheet
				isOpen={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				title={({ titleId }) => (
					<div className={styles.detailsDrawerTitle}>
						<div />
						<h3 className={styles.drawerTitle}>{t(jobDetailsAvailableShifts)}</h3>
						<BottomSheetCloseButton
							aria-label={t('ariaLabelTexts.closeDialog')}
							onClick={() => setDrawerOpen(false)}
						/>
					</div>
				)}>
				{shiftDetails()}
			</BottomSheet>
		);
	};

	useEffect(() => {
		if (isPortrait) {
			dispatch(
				subHeader({ title: 'jobSelection.jobDetails', routeTo: 'job-search', display: 'true' })
			);
		}
		// eslint-disable-next-line
	}, [isPortrait]);

	return (
		<div>
			{detailsComp()}
			<div className={styles.buttonHolder}>
				{checkIfPreferred(item) ? (
					<Button className={styles.jobDetailsButton} disabled size="medium">
						{t('jobFilter.selected')}
					</Button>
				) : (
					<Button
						size="medium"
						className={styles.jobDetailsButton}
						variant={
							selectedJobsStore.currentSelectedJobs.some(
								(i) => i.jobSelectionId === item.jobSelectionId
							)
								? 'secondary'
								: 'primary'
						}
						onClick={selectCurrent}>
						{selectedJobsStore.currentSelectedJobs.some(
							(i) => i.jobSelectionId === item.jobSelectionId
						)
							? t('general.remove')
							: t('general.select')}
					</Button>
				)}
			</div>
		</div>
	);
};

export default JobDetails;	
